import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import logo from './logo.svg';
import { OneSection, Toc } from './Content';
//import './App.css';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom"
import About from './About'
import NotFound from './NotFound'
import Scroller from './Scroller'
import Login from './Login'
import AudioView from './Audio'
//import Audio from './Audio'
import "./css/menu.css";
import "./css/bob.css";
import * as util from "./utilities"
import {
  toggleAudio, selectAudioVisable, selectToc,
  selectCurrentSectionId, selectAudioUrls, selectInitialized,
  selectStatus, initializeContent, selectEmail, selectUserData
} from './globalStore/contentSlice';
import ScaleLoader from "react-spinners/ScaleLoader"


function App(props) {
  console.log("Props in App", JSON.parse(JSON.stringify(props)))
  let [menuIsOpen, setMenuIsOpen] = useState(false)
  const dispatch = useDispatch();
  const initialized = useSelector(selectInitialized)
  const status = useSelector(selectStatus);
  const userData = useSelector(selectUserData);
  const toc = useSelector(selectToc)
  //const currentSectionId = useSelector(selectCurrentSectionId)
  //const audioUrls = useSelector(selectAudioUrls)
  //const audioVisible = useSelector(selectAudioVisable)
  //localStorage.setItem("email", "bboiko@uw.edu")
  let userEmail = userData && userData.userEmail ? userData.userEmail : localStorage.getItem("email")
  if (!userEmail) { return <Login /> }
  if (!initialized && status == "idle") {
    dispatch(initializeContent(userEmail))
    return <div>
      <p  className="loginTitle">Loading your account</p>
      <div style={{ textAlign: "center" }}>
        <ScaleLoader color="white" loading={true} size={50} />
      </div>
    </div>
  }
  if (initialized && toc) {
      let thereIsARoute = true //false
      if (window.location.hash.includes("/") && window.location.hash.split("/")[1].length > 0) thereIsARoute = true
      if (!userEmail && thereIsARoute) {
        window.location.hash = ""
      } else if (userEmail && !thereIsARoute) {
        window.location.hash = "/home"
      } else {
        const menuStateChange = (state) => {
          setMenuIsOpen(state.isOpen)
        }
        const closeMenu = () => {
          setMenuIsOpen(false)
        }

        const doToggleAudio = () => {
          closeMenu()
          dispatch(toggleAudio())
        }

        const logout = () => {

        }
        return (
          <Router>
            <div className="topContainer" >
              <div className="contentArea" >
                <Switch>
                  <Route exact path="/" render={(routerProps) => { return (<AudioView {...routerProps} />) }}></Route>
                  <Route exact path="/home" render={(routerProps) => { return (<OneSection {...routerProps} />) }}></Route>
                  <Route exact path="/home/:sectionId" render={(routerProps) => { return (<OneSection {...routerProps} />) }}></Route>
                  <Route exact path="/toc" render={(routerProps) => { return (<Toc {...routerProps} />) }}></Route>
                  <Route exact path="/scroller" render={(routerProps) => { return (<Scroller {...routerProps} />) }}></Route>
                  <Route exact path="/about" render={(routerProps) => { return (<About {...routerProps} />) }}></Route>
                  <Route path="*" render={(routerProps) => { return (<NotFound {...routerProps} />) }} />
                </Switch>
              </div>
            </div>
          </Router>
        )
      }
    } else {
      return (
        <div>
          <p className="loginTitle">The Last Chameleon</p>
          <p className="loginTitle">8/1/2021 Edition</p>
          <p className="loginTitle">Is Loading</p>
          <div style={{ textAlign: "center" }}>
            <ScaleLoader color="white" loading={true} size={50} />
          </div>
        </div>
      )
    }
  }




export default App;
