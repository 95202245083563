import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counter/counterSlice';
import contentReducer from './contentSlice';

export const store = configureStore({
  reducer: {
    content: contentReducer,
    counter: counterReducer,
  },
});
