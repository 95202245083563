import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector, useDispatch, useStore } from 'react-redux';
import Amplitude from 'amplitudejs';
import * as C from "./constants"
import {
  selectSongs, selectUserData, selectCurrentAudioTime, selectCurrentSectionId, selectCurrentSectionTitle, selectToc,
  setCurrentSectionId, setCurrentAudioTime, setCurrentSectionTitle,
  decrement, increment, incrementByAmount, incrementAsync, incrementIfOdd, selectCount,
  selectSectionSequence, selectAudioUrls, selectInitialized, selectStatus,
  getSectionAsync, initializeContent, selectAudioVisable, selectCurrentTime
} from './globalStore/contentSlice';
import * as util from "./utilities"
import "./css/bob.css";
import "./css/amplitude.css";
import { useSwipeable } from 'react-swipeable';
import { callBackend } from './callBackend';
import iconToc from './icons/icon-toc.png'
import iconSettings from './icons/icon-settings1.png'
import { Autoplay } from 'swiper';
import moment, { max } from 'moment';
import RingLoader from "react-spinners/RingLoader"

export function AudioView(props) {
  const dispatch = useDispatch();
  const toc = useSelector(selectToc)
  const userData = useSelector(selectUserData)
  //const speechMarks = useSelector(selectSpeechMarks)
  //let currentSpeechMarks = []
  let lastTime = 0
  let autoPlay = true
  let cameFromForwardOrReverse = false
  let songs = JSON.parse(JSON.stringify(useSelector(selectSongs)))
  //songs.start_song = 3

  const updateStore = (songIndex) => {
    dispatch(setCurrentSectionId(songs.songs[songIndex].id))
    dispatch(setCurrentSectionTitle(songs.songs[songIndex].name))
  }
  const handleSongChange = () => {
    // setDivVisability("show", "loading")
    if (!cameFromForwardOrReverse) {
      const songIndex = Amplitude.getActiveIndex()
      const sectionId = songs.songs[songIndex].id
      //currentSpeechMarks = speechMarks[sectionId].marks
      //readAlongRender(currentSpeechMarks)
      setUpDivs(songIndex);
      updateStore(songIndex)
    } else {
      cameFromForwardOrReverse = false
    }
    // setDivVisability("hide", "loading")

  }
  const handleTimeUpdate = (event) => {
    const secondsLong = Amplitude.getSongPlayedSeconds()
    const listenedTill = parseInt(secondsLong)
    const songIndex = Amplitude.getActiveIndex()
    // updateReadAlong(secondsLong)
    if (listenedTill > lastTime + 4) {
      lastTime = listenedTill
      const userId = userData.user.id
      const sectionId = songs.songs[songIndex].id
      callBackend("post", "users/logTimeForUserSection", { userId, sectionId, listenedTill })
      dispatch(setCurrentAudioTime(Amplitude.getSongPlayedSeconds()))
    }
  }
  songs.callbacks = {
    "song_change": () => handleSongChange(),
    "timeupdate": (e) => handleTimeUpdate(e)
  }
  useEffect(() => {
    Amplitude.init(songs);
    document.getElementById("sectionHtml") && getSectionHtml()
    resumeWhereLeftOff()
  })
  const getSectionHtml = (index) => {
    const songIndex = index ? index : Amplitude.getActiveIndex()
    const currentSectionId = songs.songs[songIndex].id
    let sectionHtml = null
    toc.forEach((part) => {
      if (currentSectionId == part.id) {
        sectionHtml = util.decodeJsonString(part.encodedHtml)
      } else if (part.sections) {
        part.sections.forEach((section) => {
          if (section.id == currentSectionId) sectionHtml = util.decodeJsonString(section.encodedHtml)
        })
      }
    })
    document.getElementById("sectionHtml").innerHTML = sectionHtml
  }
  const getSongIndexFromId = (sectionId) => {
    let output = "notFound"
    songs.songs.forEach((song, index) => {
      if (song.id == sectionId) output = index
    })
    return output
  }
  const resumeWhereLeftOff = () => {
    if (Amplitude.getSongs().length > 0 && userData.userAudio && userData.userAudio.length > 0) {
      const lastPlayed = userData.userAudio[0]
      const songIndex = getSongIndexFromId(lastPlayed.sectionId)
      Amplitude.skipTo(lastPlayed.listenedTill, songIndex)
      setUpDivs(songIndex)
    }
  }
  const hideResumeDiv = () => {
    var div = document.getElementById("resume");
    div.classList.remove("resumeOpen")
    div.classList.add("resumeClosed")
  }
  const setProgress = (e) => {
    const progressElement = document.getElementById(e.target.id)
    var offset = progressElement.getBoundingClientRect();
    var x = e.pageX - offset.left;
    Amplitude.setSongPlayedPercentage((parseFloat(x) / parseFloat(progressElement.offsetWidth)) * 100);
  }
  const backOrForward = (direction, seconds) => {
    const songIndex = Amplitude.getActiveIndex()
    const currSeconds = Amplitude.getSongPlayedSeconds()
    const songDuration = Amplitude.getSongDuration()
    let newSeconds = null
    switch (direction) {
      case "forward":
        if (currSeconds + seconds > songDuration) {
          newSeconds = songDuration
        } else {
          newSeconds = currSeconds + seconds
        }
        break
      case "back":
        if (currSeconds - seconds < 0) {
          newSeconds = 0
        } else {
          newSeconds = currSeconds - seconds
        }
        break
      default:
        alert(`Direction passed to backOrForward:${direction} is not valid`)
    }
    cameFromForwardOrReverse = true
    Amplitude.skipTo(newSeconds, songIndex)
  }
  const nextOrPrevious = (direction) => {
    const songIndex = Amplitude.getActiveIndex()
    let newIndex = null
    switch (direction) {
      case "next":
        if (songIndex > songs.songs.length - 1) {
          newIndex = 0
        } else {
          newIndex = songIndex + 1
        }
        break
      case "previous":
        if (songIndex == 0) {
          newIndex = songs.songs.length - 1
        } else {
          newIndex = songIndex - 1
        }
        break
      default:
        alert(`Direction passed to nextOrPrevious:${direction} is not valid`)
    }

    Amplitude.playSongAtIndex(newIndex)
    setUpDivs(newIndex)
    autoStartOrStopSong()
  }
  const autoStartOrStopSong = () => {
    let playButton = document.getElementById("play-pause")
    if (autoPlay) {
      // Amplitude.play()
      playButton.classList.remove("amplitude-paused")
      playButton.classList.add("amplitude-playing")
    } else {

      playButton.classList.add("amplitude-paused")
      playButton.classList.remove("amplitude-playing")
      try {
        Amplitude.pause()
      }
      catch (err) {
        debugger
      }

    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: (e) => nextOrPrevious("next"),
    onSwipedRight: (e) => nextOrPrevious("previous"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    delta: 60
  });
  const setUpDivs = (newIndex) => {
    const sectionId = songs.songs[newIndex].id
    const sectionImage = songs.songs[newIndex].cover_art_url
    document.getElementById("sectionImageContainer").style.backgroundImage = `url('${sectionImage}')`;
    const sceneNumber = songs.songs[newIndex].sceneNumber ? songs.songs[newIndex].sceneNumber : ""
    document.getElementById("sceneNumber").innerHTML = `<span>${sceneNumber}</span>`

    document.getElementById("sectionHtml").scrollIntoView()
    window.scrollTo(0, 0);
    lastTime = 0
    getQuote(newIndex)

    //cover
    if (newIndex == 0) {
      setDivVisability("hide", "inWhich")
      setDivVisability("hide", "sectionHtmlContainer")
    } else {
      setDivVisability("show", "inWhich")

      //parts or prelude
      if (sectionId == "prelude") { getSectionHtml(newIndex) }
      if (sectionId == "prelude" || sectionId.startsWith("p")) {
        setDivVisability("hide", "quoteContainer")
      } else {
        //sections
        setDivVisability("show", "quoteContainer")
        getSectionHtml(newIndex)
      }
      //parts
      if (sectionId.startsWith("p") && sectionId != "prelude") {
        setDivVisability("hide", "sectionImageContainer")
        setDivVisability("show", "scene-part")
        toc.forEach((part) => {
          if (part.id.startsWith("p") && part.id != "prelude") {
            //debugger
            const doWhat = part.id == sectionId ? "show" : "hide"
            setDivVisability(doWhat, part.id)
          }
        })
        setDivVisability("hide", "sectionHtmlContainer")
      } else {
        setDivVisability("show", "sectionImageContainer")
        setDivVisability("hide", "scene-part")
        setDivVisability("show", "sectionHtmlContainer")
      }
    }
    //about
    if (sectionId == "about") { setDivVisability("hide", "quoteContainer") }
  }
  const nonSongScreens = ["menuScreen"]
  const toggleNonSongScreen = (doWhat, screenId,e) => {
   e.stopPropagation()
    switch (doWhat) {
      case "hide":
        setDivVisability("show", "songScreen")
        setDivVisability("show", "audioDiv")
        setDivVisability("hide", screenId)
        break
      case "show":
        setDivVisability("hide", "songScreen")
        setDivVisability("hide", "audioDiv")
        setDivVisability("show", screenId)
        break
      default:
        alert(`Bad doWhat:${doWhat} passed to toggleNonSongScreen`)
    }

    nonSongScreens.forEach((id) => { if (id != screenId) setDivVisability("hide", id) })
  }
  const goto = (sectionId) => {
    const newIndex = getSongIndexFromId(sectionId)
    Amplitude.playSongAtIndex(newIndex)
    nonSongScreens.forEach((id) => { setDivVisability("hide", id) })
    setDivVisability("show", "songScreen")
    setDivVisability("show", "audioDiv")
    setUpDivs(newIndex)
    autoStartOrStopSong()
  }
  const setDivVisability = (doWhat, divName) => {

    const showStyle = divName == "inWhich" ? "showInline" : "show"
    const hideStyle = divName == "menu" ? "fadeOut" : "hide"
    if (divName == "parts") {
      var divs = document.getElementsByClassName("partDivOuter")
      const l = divs.length;
      for (let i = 0; i < l; i++) {
        if (doWhat == "hide") {
          divs[i].classList.remove(showStyle)
          divs[i].classList.add(hideStyle)
        } else {
          divs[i].classList.remove(hideStyle)
          divs[i].classList.add(showStyle)
        }
      }
    } else {
      if (!document.getElementById(divName)) debugger
      let classList = document.getElementById(divName).classList
      if (doWhat == "hide") {
        classList.remove(showStyle)
        classList.add(hideStyle)
      } else {
        classList.remove(hideStyle)
        classList.add(showStyle)
      }
    }
  }
  const toggleAutoPlay = (alsoPlayPause) => {
    let playButton = document.getElementById("play-pause")
    if (alsoPlayPause && autoPlay) {
      Amplitude.pause()
      playButton.classList.remove("amplitude-playing")
      playButton.classList.add("amplitude-paused")

    }
    if (alsoPlayPause && !autoPlay) {
      Amplitude.play()
      playButton.classList.remove("amplitude-paused")
      playButton.classList.add("amplitude-playing")
    }

    autoPlay = !autoPlay
    console.log("autoPlay", autoPlay)
  }
  const getQuote = (songIndex) => {
    const song = songs.songs[songIndex]
    let quoteDiv = document.getElementById("quote")
    let inner = ""
    if (song && song.quote) {
      inner = `<span class="bob-quote">${song.quote && song.quote.trim()}</span>`
      if (song.speaker != "attribution no") { inner += ` <span class="bob-quote-speaker">${song.speaker}</span>` }
    }
    quoteDiv.innerHTML = inner
  }
  const setMenuTab = (tab) => {
    let indexTab = document.getElementById("indexTab")
    let indexButton = document.getElementById("indexButton")

    let tocTab = document.getElementById("tocTab")
    let tocButton = document.getElementById("tocButton")

    let settingsTab = document.getElementById("settingsTab")
    let settingsButton = document.getElementById("settingsButton")
    switch (tab) {
      case "settingsTab":
        settingsButton.classList.add("tabSelected")
        settingsTab.classList.remove("hide")
        settingsTab.classList.add("show")

        tocButton.classList.remove("tabSelected")
        tocTab.classList.add("hide")
        tocTab.classList.remove("show")

        indexButton.classList.remove("tabSelected")
        indexTab.classList.add("hide")
        indexTab.classList.remove("show")

        break
      case "indexTab":
        indexButton.classList.add("tabSelected")
        indexTab.classList.remove("hide")
        indexTab.classList.add("show")

        tocButton.classList.remove("tabSelected")
        tocTab.classList.add("hide")
        tocTab.classList.remove("show")

        settingsButton.classList.remove("tabSelected")
        settingsTab.classList.add("hide")
        settingsTab.classList.remove("show")
        break
      case "tocTab":
        tocButton.classList.add("tabSelected")
        tocTab.classList.add("show")
        tocTab.classList.remove("hide")

        indexButton.classList.remove("tabSelected")
        indexTab.classList.add("hide")
        indexTab.classList.remove("show")

        settingsButton.classList.remove("tabSelected")
        settingsTab.classList.add("hide")
        settingsTab.classList.remove("show")
        break
      default:
        alert(`unknown tab ${tab}`)
    }

    // if (tab == "indexTab") {
    //   indexTabButton.classList.add("tabSelected")
    //   indexTab.classList.remove("hide")
    //   indexTab.classList.add("show")
    //   tocButton.classList.remove("tabSelected")
    //   tocTab.classList.add("hide")
    //   tocTab.classList.remove("show")
    // } else {
    //   tocButton.classList.add("tabSelected")
    //   tocTab.classList.add("show")
    //   tocTab.classList.remove("hide")
    //   indexTabButton.classList.remove("tabSelected")
    //   indexTab.classList.add("hide")
    //   indexTab.classList.remove("show")
    // }
  }
  const getSectionImage = (songIndex) => {
    return songs.songs[songIndex].cover_art_url
  }
  const getSceneNumber = (songIndex) => {
    if (!songIndex) return null
    return songs.songs[songIndex] && songs.songs[songIndex].sceneNum ? songs.songs[songIndex].sceneNum : 0
  }

  const logout = () => {
    localStorage.clear();
    window.location.reload()
  }

  const convertToSmallImageName =(url)=>{
    let urlParts = url.split("/")
    //https://thelastchameleon-assets.s3.us-west-2.amazonaws.com/images/s_11.jpeg
    const imageFileName  = urlParts[4]
    const imageFileNameParts = imageFileName.split(".")
    const newFileName = imageFileNameParts[0] + ".jpg"
    urlParts.splice(4, 0, "small");
    urlParts.splice(5, 1, newFileName);

    const output=  urlParts.join("/")
    return output

  }
  return (
    <div>
      <div id="songScreen" className="scene-container">
        <div className="scene-bg" {...handlers} onClick={(e) => { toggleAutoPlay(true) }}>
          <div className="scene-header">
            <div className="scene-menu">
              <img src={iconToc} alt="Navigate chapters" className="scene-icon" onClick={(e) => { toggleNonSongScreen("show", "menuScreen",e) }}></img>
            </div>
            <div className="scene-text">
              <h1 data-amplitude-song-info="name" className="scene-title"></h1>
              <p className="scene-tagline"><span id="inWhich" className="hide">In which </span><span data-amplitude-song-info="artist" ></span></p>
            </div>
            <div id="sceneNumber" className="scene-number">
              <span>{getSceneNumber()}</span>
            </div>

          </div>
          <div id="sectionImageContainer" className="scene-cover" style={{
            background: `url('${getSectionImage(Amplitude.getActiveIndex())}')`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
          }}>
          </div>
          {/* part pages */}
          <div id="scene-part" className="scene-cover scene-part hide">
            {toc.map((part) => {
              if (part.id.startsWith("p")) {
                const sIndex = part.sections ? getSongIndexFromId(part.sections[0].id) : 0
                const imageUrl = songs.songs[sIndex].cover_art_url
                return (
                  <div key={part.id} id={part.id} className="partDivOuter hide">
                    <img src={imageUrl} className="partDivBackgroundImage" ></img>
                    <div className="partDivInner" >

                      <h2>Scenes in this part</h2>
                      <hr />
                      {part.sections && part.sections.map((section, index) => {
                        return (<p key={index} className="sectionLink" onClick={() => { goto(section.id) }}><b>{section.number}</b> {section.title}</p>)
                      })}
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                )
              }
            })}
          </div>
          <div className="scene-description" id="quoteContainer" onClick={(e) => { toggleAutoPlay(true) }}>
            <p id="quote" className="scene-quote">{/* Filled by getQuote */}</p>
          </div>
        </div>
        <div id="sectionHtmlContainer" className="scene-info hide">
          <div id="sectionHtml" className="sectionContent" onClick={(e) => { toggleAutoPlay(true) }}></div>
        </div>
      </div>

      {/* audio player */}
      <div id="audioDiv" className="bob-audio-outer-div show">
        <div id="player-progress-bar-container">
          <progress onClick={(e) => { setProgress(e) }} id="song-played-progress" className="amplitude-song-played-progress"></progress>
          <progress id="song-buffered-progress" className="amplitude-buffered-progress" value="0"></progress>
        </div>
        <div id="player-middle">
          <div id="time-container">
            <span className="amplitude-current-time time-container"></span>
            <span className="amplitude-time-remaining time-container"></span>
          </div>
          <div id="player-bottom">
            <div id="control-container" >
              <div id="prev-container">
                <div onClick={() => nextOrPrevious("previous")} id="previous"></div>
              </div>
              <div id="back15-container">
                <div onClick={() => { backOrForward("back", 15) }} className="amplitude-back15" id="back15">-15</div>
              </div>
              <div id="play-pause-container">
                <div onClick={() => { toggleAutoPlay() }} className="amplitude-play-pause" id="play-pause"></div>
              </div>
              <div id="forward15-container">
                <div onClick={() => { backOrForward("forward", 15) }} className="amplitude-forward15" id="forward15">+15</div>
                {/* <p className="amplitude-playback-speed speedButton"></p> */}
              </div>
              <div id="next-container">
                <div onClick={() => nextOrPrevious("next")} id="next"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Menu Screen */}
      <div id="menuScreen" className="hide">
        <div className="scene-header">
          <div className="scene-menu">
            <img src={iconToc} alt="Back to the book" className="scene-icon" onClick={(e) => { toggleNonSongScreen("hide", "menuScreen",e) }}></img>
          </div>
          <div className="scene-text">
            <h1 className="scene-title">Table of Contents</h1>
          </div>
          <div className="scene-menu">
          </div>
        </div>

        <div className="menuScreenTabs">
          <span id="indexButton" className="tabSelected menuScreenTab" onClick={() => { setMenuTab("indexTab") }} >Pictures</span>
          <span id="tocButton" className=" menuScreenTab" onClick={() => { setMenuTab("tocTab") }} >Text</span>
          <span id="settingsButton" className=" menuScreenTab" onClick={() => { setMenuTab("settingsTab") }} >
            <img src={iconSettings} onClick={() => { setMenuTab("settingsTab") }} alt="Settings" className="scene-icon" ></img>
          </span>

        </div>


        {/* TOC tab */}
        <div id="tocTab" className="hide">
          <div style={{ padding: "3px" }} >
            <p className="tocPart">Front Matter</p>
            {songs.songs.map((song, index) => {
              const paraClass = song.name.startsWith("Part") ? "tocPart" : "tocSection"
              return (
                <p key={index} className={paraClass} onClick={() => { goto(song.id) }}>{song.sceneNumber && `${song.sceneNumber}: `}{song.name}</p>
              )
            })}
          </div>
        </div>
        {/* Index tab */}
        <div id="indexTab" >
          <div className="indexTabInner" >
            {songs.songs.map((song, index) => {
              return (
                <div key={index} className={song.name.startsWith("Part") && "partHeader"}>
                  {song.name.startsWith("Part") &&
                    <div className="pictureIndexPartContainer">
                      <div className="pictureIndexPartHeader">
                        <h3><b>{song.name.split(":")[0]}:</b> {song.name.split(":")[1]}</h3>
                      </div>
                      <div className="pictureIndexPartBackground">
                        <img src={songs.songs[index + 1].cover_art_url + "-transparent.jpeg"} />
                      </div>
                    </div>

                  }
                  {!song.name.startsWith("Part") && <div key={index} className="pictureIndexDiv" onClick={() => { goto(song.id) }}>
                    <div className="pictureIndexDivHeader"><b>{song.sceneNumber && `${song.sceneNumber}: `}{song.name}</b></div>
                    <img className="pictureIndexDivImage" src={convertToSmallImageName(song.cover_art_url)}></img>
                  </div>}
                </div>
              )
            })}
          </div>
        </div>

        {/* Settings tab */}
        <div id="settingsTab" className="hide" style={{ height: "100vh" }}>
          <p style={{ fontSize: "1.3em", marginLeft: "5px", textAlign: "center" }}>Settings</p>
          <button onClick={() => { logout() }} style={{ padding: "10px 5px", margin: "15px", float: "right" }}>Logout</button>
          <div style={{ padding: "15px", marginTop: "50px" }}>
            <div>
              <p style={{marginBottom: "10px"}}>
                Toggle Audio Speed:
              </p>
              <span id="speed-toggle" className="amplitude-playback-speed speedButton" style={{ display: "inline-block", marginRight: "10px", border: "1px solid black", borderRadius: "3px", color: "black", padding: "10px", textAlign: "center" }}>
              <div style={{position:"absolute"}}>
                <div className="corey-arrow-right"></div>
              </div>
              </span>
              </div>

          </div>
        </div>
      </div>
    </div>

  )

  // const old = (
  //   <div>
  //     {/* OLD CODE section screen */}
  //     {/* Loading screen */}
  //     <div id="loading" className="hide">
  //       {/* <RingLoader color="white" loading={true} size={100} /> */}
  //     </div>
  //     <div id="songScreen" >
  //       <div id="songScreenInner" {...handlers}  >
  //         {/* section outer container- header,image,quote,text*/}
  //         <div id="playerContainer" style={{ flexGrow: 1 }} >
  //           {/* section inner container- header,image,quote,text */}
  //           <div id="playerScreen">
  //             {/* header */}
  //             <div id="playerHeaderOuter" >
  //               {/* header inner container */}
  //               <div id="playerHeaderInner" onClick={(e) => { toggleAutoPlay(true) }}>
  //                 <p data-amplitude-song-info="name" className="bob-section-title"></p>
  //                 <p className="bob-section-in-which"><span id="inWhich" className="hide">In which </span><span data-amplitude-song-info="artist" ></span></p>
  //               </div>
  //             </div>
  //             {/* resume buttons */}
  //             {userData.userAudio && userData.userAudio.length > 0 &&
  //               <div style={{ flexBasis: "35px" }} id="resume" className="resumeOpen">
  //                 <button className="resumeButton" onClick={() => { resumeWhereLeftOff() }}>Resume</button>
  //                 <button className="resumeButton" onClick={() => { hideResumeDiv() }}>Cancel</button>
  //               </div>
  //             }
  //             {/* image */}
  //             <div id="sectionImageContainer" onClick={(e) => { toggleAutoPlay(true) }} >
  //               <img className="sectionImage" data-amplitude-song-info="cover_art_url" />
  //             </div>
  //             {/* part pages */}
  //             <div>
  //               {toc.map((part) => {
  //                 if (part.id.startsWith("p")) {
  //                   const sIndex = part.sections ? getSongIndexFromId(part.sections[0].id) : 0
  //                   const imageUrl = songs.songs[sIndex].cover_art_url
  //                   return (
  //                     <div key={part.id} id={part.id} className="partDivOuter hide">
  //                       <div className="partDivInner" >
  //                         {part.sections && part.sections.map((section, index) => {
  //                           return (<p key={index} className="sectionLink" onClick={() => { goto(section.id) }}>{section.title}</p>)
  //                         })}
  //                         <img src={imageUrl} className="partDivBackgroundImage" ></img>
  //                       </div>
  //                     </div>
  //                   )
  //                 }
  //               })}
  //             </div>
  //             {/* section quote */}
  //             <div id="quoteContainer" onClick={(e) => { toggleAutoPlay(true) }}>
  //               <p id="quote" className="bob-quote-container">
  //                 {/* Filled by getQuote */}
  //               </p>
  //             </div>

  //           </div>
  //           {/* section text */}
  //           <div id="sectionHtmlContainer" className="hide" style={{ lineHeight: "133%" }}>
  //             <div id="sectionHtml" onClick={(e) => { toggleAutoPlay(true) }}></div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     {/* audio controls */}
  //     <div id="audioDiv" className="bob-audio-outer-div show">
  //       <div className="menuBar" >
  //         <span className="menuButton" onClick={() => { toggleNonSongScreen("show", "menuScreen") }}>TOC</span>

  //       </div>
  //       <div id="player-progress-bar-container">
  //         <progress onClick={(e) => { setProgress(e) }} id="song-played-progress" className="amplitude-song-played-progress"></progress>
  //         <progress id="song-buffered-progress" className="amplitude-buffered-progress" value="0"></progress>
  //       </div>
  //       <div id="player-middle">
  //         <div id="time-container">
  //           <span className="amplitude-current-time time-container"></span>
  //           <span className="amplitude-time-remaining time-container"></span>
  //         </div>
  //         <div id="player-bottom">
  //           <div id="control-container" >
  //             <div id="prev-container">
  //               <div onClick={() => nextOrPrevious("previous")} id="previous"></div>
  //             </div>
  //             <div id="back15-container">
  //               <div onClick={() => { backOrForward("back", 15) }} className="amplitude-back15" id="back15">-15</div>
  //             </div>
  //             <div id="play-pause-container">
  //               <div onClick={() => { toggleAutoPlay() }} className="amplitude-play-pause" id="play-pause"></div>
  //             </div>
  //             <div id="forward15-container">
  //               <div onClick={() => { backOrForward("forward", 15) }} className="amplitude-forward15" id="forward15">+15</div>
  //               <p className="amplitude-playback-speed speedButton"></p>
  //             </div>
  //             <div id="next-container">
  //               <div onClick={() => nextOrPrevious("next")} id="next"></div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>

  //     {/* Menu Screen */}
  //     <div id="menuScreen" className="hide">
  //       <div style={{ display: "flex", flexDirection: "row", wrap: "nowrap", marginBottom: "20px" }}>
  //         <span id="indexButton" className="tabSelected menuScreenTab" onClick={() => { setMenuTab("indexTab") }} >Picture Index</span>
  //         <span id="tocButton" className=" menuScreenTab" onClick={() => { setMenuTab("tocTab") }} >Table of Contents</span>
  //         <span onClick={() => toggleNonSongScreen("hide", "menuScreen")} className="closeButtonOnWhite">X</span>
  //       </div>
  //       {/* TOC tab */}
  //       <div id="tocTab" className="hide">
  //         <div style={{ padding: "3px" }} >
  //           <p className="tocPart">Front Matter</p>
  //           {songs.songs.map((song, index) => {
  //             const paraClass = song.name.startsWith("Part") ? "tocPart" : "tocSection"
  //             return (
  //               <p key={index} className={paraClass} onClick={() => { goto(song.id) }}>{song.name}</p>
  //             )
  //           })}
  //         </div>
  //       </div>
  //       {/* Index tab */}
  //       <div id="indexTab" >
  //         <div className="indexTabInner" >
  //           {songs.songs.map((song, index) => {
  //             return (
  //               <div key={index} className="pictureIndexDiv" onClick={() => { goto(song.id) }}>
  //                 <div className="pictureIndexDivHeader"><b>{song.name}</b></div>
  //                 {!song.name.startsWith("Part") && <img className="pictureIndexDivImage" src={song.cover_art_url}></img>}
  //               </div>
  //             )
  //           })}
  //         </div>
  //       </div>
  //     </div>
  //   </div>

  // )
}

export default AudioView;


//********************Old code to do readalong  */
 // const xxupdateReadAlong = (time) => {
  //   const songIndex = Amplitude.getActiveIndex()
  //   let currentIndex = 0
  //   currentSpeechMarks.forEach((sentence, index) => {
  //     const currSentenceTime = sentence.time / 1000
  //     const nextSentenceTime = index < currentSpeechMarks.length - 1 ? currentSpeechMarks[index + 1].time : 100000000
  //     if (time >= currSentenceTime && time <= nextSentenceTime) { currentIndex = index }
  //   })
  //   currentSpeechMarks.forEach((sentence, index) => {
  //     const sentencePara = document.getElementById(`mark${index}`)
  //     switch (true) {
  //       case currentIndex == index:
  //         sentencePara.className = "readAlongCurrent"
  //         break
  //       case Math.abs(index - currentIndex) == 1:
  //         sentencePara.className = "readAlongOneAway"
  //         break
  //       default:
  //         sentencePara.className = "readAlongDefault"
  //     }
  //   })
  //   const divToScrollTo = currentIndex > 1 ? `mark${currentIndex - 1}` : "mark0"
  //   document.getElementById(divToScrollTo).scrollIntoView()

  // }

  // const readAlongGoTo = (milliseconds) => {
  //   const newSeconds = milliseconds / 1000
  //   updateReadAlong(newSeconds)
  //   const songIndex = Amplitude.getActiveIndex()
  //   cameFromForwardOrReverse = true
  //   Amplitude.skipTo(newSeconds, songIndex)
  // }
  // const toggleReadAlong = () => {
  //   let readAlongDiv = document.getElementById("readAlongControl")
  //   const currentClasses = readAlongDiv.classList
  //   if (currentClasses.contains("show")) {
  //     readAlongDiv.classList.remove("show")
  //     readAlongDiv.classList.add("hide")
  //     setDivVisability("show", "audioDiv")
  //   } else {
  //     readAlongDiv.classList.add("show")
  //     readAlongDiv.classList.remove("hide")
  //     setDivVisability("hide", "audioDiv")
  //   }
  // }
  // const readAlongRender = (marks) => {
  //   console.log("**********didReadAlongRender", marks.length)
  //   let output = ``
  //   {
  //     marks.map((sentence, index) => {
  //       if (index == 0) {
  //         output += `<p id="${`mark${index}`}" style="margin-left:28px">${sentence.value}</p>`
  //       } else {
  //         output += `<p id="${`mark${index}`}" >${sentence.value}</p>`
  //       }
  //     })
  //   }
  //   let targetDiv = document.getElementById("readAlongSentences")
  //   targetDiv.innerHTML = output
  //   marks.forEach((sentence, index) => {
  //     document.getElementById(`mark${index}`).addEventListener("click", () => readAlongGoTo(sentence.time))
  //   })
  // }

  // export function AddComments(props) {
//   console.log("Props in Comments", props)
//   const currentSectionId = useSelector(selectCurrentSectionId)
//   const currentSectionTitle = useSelector(selectCurrentSectionTitle)
//   const currentAudioTime = useSelector(selectCurrentAudioTime)
//   const initialized = useSelector(selectInitialized)
//   let [dirty, setDirty] = useState(true)
//   let [loaded, setLoaded] = useState(false)
//   let [comments, setComments] = useState(null)
//   let [discussions, setDiscussions] = useState({})
//   let [statuses, setStatuses] = useState({})
//   useEffect(() => {
//     if (dirty) {
//       callBackend("post", "users/getComments", {})
//         .then((results) => {
//           setComments(results.data.comments)
//           setDirty(false)
//           setLoaded(true)
//           console.log("comments state:", setDirty, comments)
//         })
//     }
//   })

//   const userId = props.userId
//   const toggleNonSongScreen = props.toggleNonSongScreen
//   const deleteComment = (id) => {
//     callBackend("post", "users/deleteComment", { id })
//       .then((results) => {
//         setDirty(true)
//       })
//   }

//   const addUpdateComment = () => {
//     const commentNode = document.getElementById("newComment")
//     const comment = util.encodeJsonString(commentNode.value)
//     callBackend("post", "users/addEditComment", { userId, sectionId: currentSectionId, comment, audioTime: currentAudioTime })
//       .then((results) => {
//         callBackend("post", "users/getComments", {})
//           .then((results) => {
//             setDirty(true)
//             commentNode.value = ""
//           })
//       })
//   }

//   const updateDiscussion = (comment) => {
//     const id = comment.id
//     let status = statuses[id] ? statuses[id] : comment.status
//     if (status == "noneYet") {
//       alert("Please select a status before submitting")
//     } else {
//       let discussion = discussions[id] ? discussions[id] : comment.discussion
//       callBackend("post", "users/updateDiscussion", { id, discussion: util.encodeJsonString(discussion), status })
//         .then((results) => {
//           setDirty(true)
//         })
//     }

//   }

//   const handleDiscussionChange = (e, commentId) => {
//     e.target.style.height = ""
//     e.target.style.height = e.target.scrollHeight + "px"
//     let discussionsObject = JSON.parse(JSON.stringify(discussions))
//     discussionsObject[commentId] = e.target.value
//     setDiscussions(discussionsObject)
//   }

//   const handleStatusChange = (e, commentId) => {
//     let statusObject = JSON.parse(JSON.stringify(statuses))
//     statusObject[commentId] = e.target.value
//     setStatuses(statusObject)
//   }

//   const outputCurrentComments = () => {
//     let output = []
//     if (comments[currentSectionId]) {
//       const sectionComments = comments[currentSectionId]
//       for (const userId in sectionComments) {
//         let userComments = sectionComments[userId]
//         let closedComments = 0
//         const email = userComments[0].email
//         userComments = userComments.filter((comment) => {
//           if (comment.status != "closed") {
//             return comment
//           } else {
//             closedComments++
//           }
//         })
//         output.push(<p key={userId}>From: <a style={{ marginRight: "5px" }} href={`mailto:${email}`}>{email}</a> ({closedComments} closed)</p>)
//         userComments.forEach((comment, index) => {
//           output.push(
//             <div key={`${index}-${userId}-${currentSectionId}`} style={{ border: "1px solid black", margin: "3px", padding: "5px" }}>
//               <p style={{ marginTop: "3px" }}>
//                 <button style={{ marginRight: "5px" }} onClick={() => { deleteComment(comment.id) }}>X</button>
//                 {moment(comment.dtCreated).format('MM/DD YYYY h:mm a')}
//                 <span style={{ float: "right" }}>{comment.audioTime} sec.</span>
//               </p>
//               <p style={{ marginLeft: "10px" }}> <i>"{util.decodeJsonString(comment.comment)}."</i></p>

//               <p style={{ marginTop: "3px", marginBottom: "3px", fontWeight: "bold" }}>Discussion</p>
//               <textarea id="discussion" value={discussions[comment.id] ? discussions[comment.id] : comment.discussion ? util.decodeJsonString(comment.discussion) : ""} onChange={(e) => { handleDiscussionChange(e, comment.id) }} rows="1" style={{ width: "95%" }}></textarea>
//               <select value={comment.status ? comment.status : "noneYet"} style={{ fontSize: "max(1em,16px)" }} onChange={(e) => handleStatusChange(e, comment.id)} >
//                 <option value="noneYet" >-select a status-</option>
//                 <option value="submitted">Submitted</option>
//                 <option value="inDiscussion">In discussion</option>
//                 <option value="closed" >Closed</option>
//               </select>
//               <button style={{ float: "right" }} onClick={() => { updateDiscussion(comment) }}>update</button>
//             </div>
//           )
//         })
//       }
//     } else {
//       output.push(<p>No comments yet</p>)
//     }
//     return output
//   }

//   if (initialized && loaded) {
//     return (
//       <div style={{ border: "2px solid black" }}>
//         <div id="commentHeader" style={{ position: "fixed", width: "100%", backgroundColor: "black", color: "white", paddingTop: "5px" }}>
//           <div style={{ display: "flex", flexDirection: "row", wrap: "nowrap" }}>
//             <span style={{ flexGrow: 1, textAlign: "center", fontSize: "1rem", paddingLeft: "25px" }}>New Comment - {currentSectionTitle} ({currentSectionId})</span>
//             <span onClick={() => toggleNonSongScreen("hide", "commentsScreen")} className="closeButtonOnWhite">X</span>
//           </div>
//         </div>

//         <div id="currentCommentsDiv" style={{ backgroundColor: "white", marginTop: "0px", padding: "50px 5px 10px 5px" }}>
//           {outputCurrentComments()}
//         </div>
//         <hr></hr>
//         <p style={{ marginLeft: "10px" }}>Add a new comment at time {currentAudioTime} </p>
//         <textarea id="newComment" rows="3" style={{ width: "90%", fontSize: "max(1em,16px)" }}></textarea>
//         <button onClick={() => { addUpdateComment() }}>Add</button>

//       </div>
//     )
//   } else {
//     return (<p>Loading comments ...</p>)
//   }

// }

// export function ViewComments(props) {
//   console.log("Props in Comments", props)
//   const initialized = useSelector(selectInitialized)
//   const toc = useSelector(selectToc)

//   let [dirty, setDirty] = useState(true)
//   let [loaded, setLoaded] = useState(false)
//   let [comments, setComments] = useState(null)
//   let [discussions, setDiscussions] = useState({})
//   let [statuses, setStatuses] = useState({})
//   useEffect(() => {
//     if (dirty) {
//       callBackend("post", "users/getComments", {})
//         .then((results) => {
//           setComments(results.data.comments)
//           setDirty(false)
//           setLoaded(true)
//           console.log("comments state:", setDirty, comments)
//         })
//     }
//   })

//   const userId = props.userId
//   const toggleNonSongScreen = props.toggleNonSongScreen
//   const deleteComment = (id) => {
//     callBackend("post", "users/deleteComment", { id })
//       .then((results) => {
//         setDirty(true)
//       })
//   }

//   const updateDiscussion = (comment) => {
//     const id = comment.id
//     let status = statuses[id] ? statuses[id] : comment.status
//     let discussion = discussions[id] ? discussions[id] : comment.discussion
//     callBackend("post", "users/updateDiscussion", { id, discussion: util.encodeJsonString(discussion), status })
//       .then((results) => {
//         setDirty(true)
//       })
//   }

//   const handleDiscussionChange = (e, commentId) => {
//     e.target.style.height = ""
//     e.target.style.height = e.target.scrollHeight + "px"
//     let discussionsObject = JSON.parse(JSON.stringify(discussions))
//     discussionsObject[commentId] = e.target.value
//     setDiscussions(discussionsObject)
//   }

//   const handleStatusChange = (e, commentId) => {
//     let statusObject = JSON.parse(JSON.stringify(statuses))
//     statusObject[commentId] = e.target.value
//     setStatuses(statusObject)
//   }

//   const outputCurrentComments = () => {
//     let output = []
//     toc.forEach((part, partIndex) => {
//       output.push(<h1 key={partIndex}>Part{part.number ? " " + part.number : ""}: {part.title}</h1>)
//       part.sections && part.sections.forEach((section, sectionIndex) => {
//         const sectionId = section.id
//         output.push(<h1 key={`${partIndex}-${sectionIndex}`} style={{ marginLeft: "10px", }}>Section {section.number}: {section.title} ({section.id})</h1>)
//         const sectionComments = comments[sectionId]
//         for (const userId in sectionComments) {
//           let userComments = sectionComments[userId]
//           let closedComments = 0
//           const email = userComments[0].email
//           userComments = userComments.filter((comment) => {
//             if (comment.status != "closed") {
//               return comment
//             } else {
//               closedComments++
//             }
//           })
//           output.push(<p key={`${userId}-${sectionId}`} style={{ marginLeft: "5px" }} >From: <a style={{ marginRight: "5px" }} href={`mailto:${email}`}>{email}</a> ({closedComments} closed)</p>)
//           userComments.forEach((comment, index) => {
//             output.push(
//               <div key={`${index}-${userId}-${sectionId}`} style={{ border: "1px solid black", margin: "3px", padding: "5px" }}>
//                 <p style={{ marginTop: "3px" }}>
//                   <button style={{ marginRight: "5px" }} onClick={() => { deleteComment(comment.id) }}>X</button>
//                   {moment(comment.dtCreated).format('MM/DD YYYY h:mm a')}
//                   <span style={{ float: "right" }}>{comment.audioTime} sec.</span>
//                 </p>
//                 <p style={{ marginLeft: "10px" }}> <i>"{util.decodeJsonString(comment.comment)}."</i></p>

//                 <p style={{ marginTop: "3px", marginBottom: "3px", fontWeight: "bold" }}>Discussion</p>
//                 <textarea id="discussion" value={discussions[comment.id] ? discussions[comment.id] : comment.discussion ? util.decodeJsonString(comment.discussion) : ""} onChange={(e) => { handleDiscussionChange(e, comment.id) }} rows="1" style={{ width: "95%" }}></textarea>
//                 <select value={comment.status ? comment.status : "noneYet"} style={{ fontSize: "max(1em,16px)" }} onChange={(e) => handleStatusChange(e, comment.id)} >
//                   <option value="noneYet" >-select a status-</option>
//                   <option value="submitted" >Submitted</option>
//                   <option value="inDiscussion">In discussion</option>
//                   <option value="closed" >Closed</option>
//                 </select>
//                 <button style={{ float: "right" }} onClick={() => { updateDiscussion(comment) }}>update</button>
//               </div>
//             )
//           })
//         }
//       })
//     })
//     return output
//   }

//   if (initialized && loaded) {
//     return (
//       <div>
//         <h3 style={{ margin: "0px", backgroundColor: "black", color: "white", textAlign: "center", padding: "10px" }}>View All Comments</h3>
//         <div id="currentCommentsDiv" style={{ backgroundColor: "#F0F0F0", marginTop: "0px", paddingTop: "5px", paddingLeft: "5px", fontSize: "9px" }}>
//           {outputCurrentComments()}
//         </div>
//       </div>
//     )
//   } else {
//     return (<p>Loading comments ...</p>)
//   }

// }

 // const startFadeTimer = () => {
  //   setTimeout(function () { turnOffAudioDisplay() }, 10000);
  // }

  // const xxtoggleAudioDisplay = () => {
  //   var div = document.getElementById("audioDiv");
  //   if (div.classList.contains("show")) {
  //     div.classList.remove("show")
  //     div.classList.add("fadeOut")
  //     //startFadeTimer()
  //   } else {
  //     div.classList.remove("fadeOut")
  //     div.classList.add("show")

  //   }
  // }

    // const turnOffAudioDisplay = () => {
  //   var div = document.getElementById("audioDiv");
  //   div.classList.remove("show")
  //   div.classList.add("fadeOut")
  // }
