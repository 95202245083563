import React from 'react'
import { Component } from 'react'

class ErrorRender extends Component {
    constructor(props) {
        super(props);
        this.state = { showMe: true }
    }
    logoutUser() {
        //store.dispatch(logoutUser())
    }
    render() {
        return <div> {this.state.showMe &&
            <div className={"wrapper"} style={{ background: "bisque", color: "red", textAlign: "center", padding: "5px" }}>
                <h3>The application has crashed unexpectedly. {this.props.message}</h3>
                <p style={{ cursor: 'pointer', color: '#337ab7' }} onClick={() => this.setState({showMe:false})}>Click here to close</p>
            </div>}
        </div>
    }
}

export default ErrorRender