import React, { useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import * as C from "./constants"
import {
  decrement, increment, incrementByAmount, incrementAsync, incrementIfOdd, selectCount,
  selectSectionSequence, selectToc, selectAudioUrls, selectInitialized, selectStatus, selectCurrentSectionId,
  setCurrentSectionId, getSectionAsync, initializeContent, selectAudioVisable,
} from './globalStore/contentSlice';
import * as util from "./utilities"
import "./css/bob.css";
import { useSwipeable } from 'react-swipeable';
import { callBackend } from './callBackend';


export function Toc(props) {
  const toc = useSelector(selectToc)
  const dispatch = useDispatch();

  const makeFullHtml = () => {
    let output = []
    toc.map((part, pIndex) => {
      if (pIndex < 2) {
        output.push(<p onClick={() => { dispatch(setCurrentSectionId({ newSectionId: part.id })); util.nextRoute(`/home/${part.id}`, props.history) }} className="tocPart">{part.title}</p>)

      } else {
        output.push(<p onClick={() => { dispatch(setCurrentSectionId({ newSectionId: part.id })); util.nextRoute(`/home/${part.id}`, props.history) }} className="tocPart">Part {pIndex - 1}: {part.title}</p>)

      }
      part.sections && part.sections.map((section, sIndex) => {
        output.push(<p onClick={() => { dispatch(setCurrentSectionId({ newSectionId: section.id })); util.nextRoute(`/home/${section.id}`, props.history) }} className="tocSection">Scene {sIndex + 1}: {section.title}</p>)
      })
    })
    return output
  }

  const makePartHtml = (partId) => {
    let output = []
    toc.map((part, pIndex) => {
      if (part.id == partId) {
        output.push(<p className="tocTitle">Part {pIndex - 1}: {part.title}</p>)
        output.push(<p className="tocSubTitle">In which {part.whatHappens}</p>)
        part.sections && part.sections.map((section, sIndex) => {
          output.push(<p onClick={() => { dispatch(setCurrentSectionId({ newSectionId: section.id })); util.nextRoute(`/home/${section.id}`, props.history) }} className="tocSection">Scene {sIndex + 1}: {section.title}</p>)
        })
      }
    })
    return output
  }
  if (props.partId) {
    return (
      <div className="nonSectionContainer">
        {makePartHtml(props.partId)}
      </div>)
  } else {
    return (
      <div className="nonSectionContainer">
        <p className="tocTitle">The Last Chameleon</p>
        <p className="tocSubTitle">Table of Contents</p>
        {makeFullHtml()}
      </div>)
  }
}

export function OneSection(props) {
  console.log("Props in OneSection", props)
  const dispatch = useDispatch();
  const initialized = useSelector(selectInitialized)
  const status = useSelector(selectStatus);
  const toc = useSelector(selectToc)
  const sectionSequence = useSelector(selectSectionSequence)
  const currentSectionId = useSelector(selectCurrentSectionId)
  const audioUrls = useSelector(selectAudioUrls)
  const audioVisible = useSelector(selectAudioVisable)
  let newSectionId = null

  if (!initialized && status == "idle") dispatch(initializeContent())

  const handlers = useSwipeable({
    onSwipedLeft: (e) => changeSection("next", e),
    onSwipedRight: (e) => changeSection("prev", e),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  const changeSection = (direction, e) => {

    //kludge to prevent clicks on play button from triggerieng a swipe envent
    let doIt = true
    if (e.event && e.event.target && e.event.path) {
      e.event.path.forEach((p) => {
        if (p.id == "audioControl") doIt = false
      })
    }
    if (doIt) {
      const currIndex = sectionSequence.indexOf(currentSectionId)
      let gotoIndex = 0
      switch (direction) {
        case "next":
          if (currIndex + 1 < sectionSequence.length) gotoIndex = currIndex + 1
          break
        case "prev":
          if (currIndex - 1 > 0) gotoIndex = currIndex - 1
          break
        default:
          alert(`bad direction ${direction} passed to swipe`)
      }
      const newSectionId = sectionSequence[gotoIndex]
      util.nextRoute(`/home/${newSectionId}`, props.history)
      dispatch(setCurrentSectionId({ newSectionId }))

    }

  }
  const updateListenedForUser = (e) => {

    console.log("44444", e)
  }
  if (initialized && toc && currentSectionId) {
    //parse toc to find html
    let sectionHtml = null
    toc.forEach((part) => {
      if (currentSectionId == part.id) {
        sectionHtml = util.decodeJsonString(part.encodedHtml)
      } else if (part.sections) {
        part.sections.forEach((section) => {
          if (section.id == currentSectionId) sectionHtml = util.decodeJsonString(section.encodedHtml)
        })
      }
    })
    // #t=10
    return (
      <div>
        <div id="audioControl">
          {audioVisible &&
            <AudioPlayer
              onEnded={(e) => changeSection("next", e)}
              onClickPrevious={(e) => changeSection("prev", e)}
              onClickNext={(e) => changeSection("next", e)}
              onListen={(e) => updateListenedForUser(e)}
              customVolumeControls={[]}
              customAdditionalControls={[]}
              // src={`${C.s3root}${audioUrls[currentSectionId].url}`}
              showSkipControls={true}
              listenInterval={5000}
            />}
        </div>
        <div >
          {currentSectionId.startsWith("p") ?
            <Toc partId={currentSectionId} {...props}></Toc>
            :
            <div {...handlers} id="sectionContent" dangerouslySetInnerHTML={{ __html: sectionHtml }} ></div>}
        </div>
      </div>
    );
  } else {
    return (<p>loading ...</p>)

  }
}



