import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './globalStore/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import ErrorRender from './GlobalError'

window.onerror =  function (event) {
  console.log("Caught error",event)

  if(!event.startsWith("Uncaught Error:")){
     ReactDOM.render((<div>
    <Provider store={store}>
      <div>
        <ErrorRender message={event.message} />
      </div>
    </Provider>
  </div>), document.getElementById('error'));
  }
 
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
