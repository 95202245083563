import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css"
import "swiper/components/pagination/pagination.min.css"
import "./css/bob.css";
import "./css/swiperStyles.css";
import SwiperCore, { EffectCoverflow, Pagination } from 'swiper/core';
import * as util from './utilities'

import { useSelector, useDispatch } from 'react-redux';
import {
    decrement, increment, incrementByAmount, incrementAsync, incrementIfOdd, selectCount,
    initializeContent, selectToc, selectSections, getSectionAsync, selectStatus, setCurrentSectionId, initialized,
} from './globalStore/contentSlice';
// install Swiper modules
SwiperCore.use([]);

export function Scroller(props) {
    const toc = useSelector(selectToc)
    const dispatch = useDispatch();
    const makeSlides = () => {
        let output = []
        toc.forEach((part) => {
            output.push(
                <SwiperSlide key={part.number}>
                    <div className="thumbnailPartContainer" onClick={() => {dispatch(setCurrentSectionId({ newSectionId: part.id })); util.nextRoute(`/home/${part.id}`, props.history) }} >
                        <h2 className="thumbnailPartTitle">Part {part.number}: {part.title}</h2>
                        <p>In which {part.whatHappens}</p>
                    </div>
                </SwiperSlide>
            )
            part.sections && part.sections.forEach((section) => {
                output.push(<SwiperSlide key={`${part.number}-${section.number}`}>
                    <div className="thumbnailSectionContainer" onClick={() => {dispatch(setCurrentSectionId({ newSectionId: section.id })); util.nextRoute(`/home/${section.id}`, props.history) }} >
                        <h2 className="thumbnailSectionTitle">{part.number}.{section.number}: {section.title}</h2>
                        <img className="thumbnailSectionImage" src={`https://thelastchameleon.s3.amazonaws.com/images/${section.id}.jpeg`} />
                    </div>
                </SwiperSlide>
                )
            })
        })
        return output
    }
    return (
        <div style={{ height: "800px" }}>
            <Swiper effect={'coverflow'} grabCursor={true} centeredSlides={true} slidesPerView={'5'} className="mySwiper" freeMode={true} >
                {makeSlides()}
                {/* <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-1.jpg" /></SwiperSlide>
                        <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-2.jpg" /></SwiperSlide>
                        <SwiperSlide>
                            <div className="sectionImageContainer">
                                <h2 className="sectionImageTitle">Scene 1: Up late.</h2>

                                <img className="sectionImage" src="http://winhost5.ischool.uw.edu/theLastChameleon/images/s_6.jpg" />


                            </div>
                        </SwiperSlide>
                        <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-4.jpg" /></SwiperSlide>
                        <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-5.jpg" /></SwiperSlide>
                        <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-6.jpg" /></SwiperSlide>
                        <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-7.jpg" /></SwiperSlide>
                        <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-8.jpg" /></SwiperSlide>
                        <SwiperSlide><img src="https://swiperjs.com/demos/images/nature-9.jpg" /></SwiperSlide> 
                    */}
            </Swiper>


        </div>
    )
}


export default Scroller
