import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { callBackend } from '../callBackend';

const initialState = {
  //email:null,
  //userId:null,
  value: 0,
  status: 'idle',
  toc: null,
  sectionSequence: [],
  initialized: false,
  audioUrls: null,
  audioVisible: true,
  songs: null,
  userData: null,
  currentSectionId: "cover",
  currentAudioTime: 0,
  currentSectionTitle: "Book Cover"
};

//if(localStorage.getItem("email")) initialState.email = localStorage.getItem("email")
//if(localStorage.getItem("userId")) initialState.email = localStorage.getItem("userId")

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectSpeechMarks = (state) => state.content.speechMarks;
export const selectCurrentSectionId = (state) => state.content.currentSectionId;
export const selectCurrentSectionTitle = (state) => state.content.currentSectionTitle;
export const selectCurrentAudioTime = (state) => state.content.currentAudioTime;
//export const selectEmail = (state) => state.content.email;
export const selectCount = (state) => state.content.value;
export const selectToc = (state) => state.content.toc;
export const selectSectionSequence = (state) => state.content.sectionSequence;

export const selectStatus = (state) => state.content.status;
export const selectInitialized = (state) => state.content.initialized;
export const selectAudioUrls = (state) => state.content.audioUrls;
export const selectAudioVisable = (state) => state.content.audioVisible;
export const selectSongs = (state) => state.content.songs;
export const selectUserData = (state) => state.content.userData;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const login = createAsyncThunk('user/loginUser',
  async (email) => {
    let response = await callBackend("post", "login", { email });
    return response.data
  }
);

export const incrementAsync = createAsyncThunk(
  'content/fetchCount',
  async (amount) => {
    const response = await callBackend("post", "content/getToc", {});
    // The value we return becomes the `fulfilled` action payload
    return response.data.num;
  }
);

export const initializeContent = createAsyncThunk('content/initializeContent',
  async (userEmail) => {
    let response = await callBackend("post", "content/getToc", {});
    const toc = response.data.toc
    response = await callBackend("post", "content/getSongs", {});
    const songs = {
      "bindings": {
        37: 'prev',
        39: 'next',
        32: 'play_pause'
      },
       songs: response.data.songs,
      debug: true,
      autoplay: false
    }
    response = await callBackend("post", "users/getUserData", { email: userEmail });
    const userData = response.data
   // response = await callBackend("post", "audio/getSpeechMarks", {});
    //const speechMarks = response.data.output
    return { toc, songs, userData}
  }
);

export const getSectionAsync = createAsyncThunk('content/getSection',
  async (sectionId) => {
    const response = await callBackend("post", "content/getSection", { sectionId });
    return response.data;
  }
);


export const contentSlice = createSlice({
  name: 'content',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCurrentAudioTime: (state, parms) => {
      state.currentAudioTime = parseInt(parms.payload)
      console.log("***Set CurrentTime", JSON.parse(JSON.stringify(state)))
    },
    setCurrentSectionId: (state, parms) => {
      state.currentSectionId = parms.payload
      console.log("***Changed current sectionId", JSON.parse(JSON.stringify(state)))
    },
    setCurrentSectionTitle: (state, parms) => {
      state.currentSectionTitle = parms.payload
      console.log("***Changed current sectionTitle", JSON.parse(JSON.stringify(state)))
    },
    toggleAudio: (state, parms) => {
      state.audioVisible = !state.audioVisible
      console.log("***Toggled audio visible", JSON.parse(JSON.stringify(state)))
    },
    increment: (state) => {

      debugger
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'idle';
        const user = action.payload.user
        state.id = user.id
        state.email = user.email
        state.type = user.type
        state.config = user.config
        localStorage.setItem("email", user.email)
        console.log(`***Login ${user.email}`, JSON.parse(JSON.stringify(state)))
      })
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value += action.payload;
      })

      .addCase(initializeContent.pending, (state) => {
        state.status = 'loading';
        state.initialized = true
      })
      .addCase(initializeContent.fulfilled, (state, action) => {
        state.status = 'idle';
        let { toc, songs, userData,speechMarks } = action.payload
        let sectionSequence = []
        toc.forEach((part) => {
          sectionSequence.push(part.id)
          part.sections && part.sections.forEach((section) => { sectionSequence.push(section.id) })
        });
        state.toc = toc
        state.sectionSequence = sectionSequence
        state.songs = songs
        state.userData = userData
        //state.speechMarks = speechMarks
        console.log("***Initialized content", JSON.parse(JSON.stringify(state)))
      })
      .addCase(getSectionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSectionAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        const sectionId = action.payload.sectionId
        state.sections[sectionId] = action.payload.sectionEncodedHtml;
        //state.currentSectionId = sectionId
        console.log(`***Got section ${sectionId}`, JSON.parse(JSON.stringify(state)))

      });
  },
});


export const { increment, decrement, incrementByAmount, setCurrentSectionId, setCurrentSectionTitle, toggleAudio, setCurrentAudioTime } = contentSlice.actions;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default contentSlice.reducer;
