import React, { useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import './css/bob.css'
import './css/corey.css'
import * as utilities from "./utilities"
import About from './About'
import { callBackend } from './callBackend';
import { login } from './globalStore/contentSlice';
function Login(props) {
    let [emailFound, setEmailFound] = useState(false)
    let [emailValid, setEmailValid] = useState(false)
    let [email, setEmail] = useState(null)
    let [msg, setMsg] = useState(null)

    const dispatch = useDispatch();

    const checkEmail = (e) => {
        const emailToCheck = e.target.value
        setEmail(emailToCheck)
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailToCheck)) {
            setEmailValid(true)
            callBackend("post", "checkEmail", { email: emailToCheck })
                .then((results) => {
                    setEmailFound(results.data.emailFound)
                }).catch((err) => { throw new Error(err) })
        } else {
            setEmailValid(false)
        }
    }

    const createAccount = () => {
        callBackend("post", "createAccount", { email })
            .then((results) => {
                setMsg(results.data.msg)
                if (results.data.status == "ok") { setEmailFound(true) }
            }).catch((err) => { throw new Error(err) })
    }
    const loginUser = (email) => {
        dispatch(login(email))
    }

    console.log("State in Login", email, emailFound)

    return (
        <div>
            <div id="error"></div>
            <div>
                <div class="login-container">
                    <div class="login-bg">
                        <div class="login-text">
                            <h1 class="login-title">The Last Chameleon</h1>
                            <p class="login-tagline">
                                By Bob Boiko
                                <br />
                                Illustrations by Manuel Figueiredo
                                <br />
                                Read by Luke Welland
                            </p>
                        </div>
                    </div>
                    <div class="login-info">
                        <div class="login-form">
                            <div class="phone-below">
                                <h2 class="login-form-title">Enter your email</h2>
                                <p class="login-form-tagline">Access the book and save your place using your email address</p>
                            </div>
                            <div class="phone-above">
                                <input class="login-email-input" type="text" value={email && email} placeholder="your@email.com" onChange={(e) => checkEmail(e)} />

                                {emailValid &&
                                    <div>
                                        {emailFound ?
                                            <button onClick={() => { loginUser(email) }}>Open the Book</button>
                                            :
                                            <button onClick={() => { createAccount() }}>Create an Account </button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default Login;
