import axios from "axios";
import * as C from "./constants"
//import { respondToError, clearAllCookies} from "./components/ErrorManager"
import cookie from 'react-cookies'

const origin = window.location.origin
const backendHost = origin.includes("localhost") ? 'http://localhost:8080/' :origin + "/"
axios.defaults.withCredentials = true

export function callBackend(method, route, parmsObject) {
    console.log(`*************-Call:${route}`)
    return new Promise(function (resolve, reject) {
        const user = "xxx" //cookie.load('user')
        if (user) {
            const baseUrl = backendHost
            //console.log("base url for backend", baseUrl)
            const url = `${baseUrl}${route}`
            switch (method) {
                case "post":
                    parmsObject = JSON.stringify(parmsObject)
                    axios.post(url, { parmsObject })
                        .then(function (response) {
                            console.log(`*************-Return from :${route} `)
                            if (response.data.error) {
                                debugger
                                if (response.data.error.status > 900) {
                                    processNonBreakingError(response.data.error)
                                } else {
                                    return reject(response)
                                }
                            } else {
                                return resolve(response)
                            }
                        }).catch((error) => {
                            if (error && error.status > 900) {
                                processNonBreakingError(error)
                            } else {
                                console.log(`Error in callBackend,error:${error} route:${url} parmsObject:${parmsObject}`)
                                return reject({ error, route: url, parmsObject })
                            }
                        })
                    break
                case "upload":
                    axios.post(url, parmsObject, { headers: { 'Content-Type': 'multipart/form-data' } })
                        .then(function (response) {
                            if (response.data.error) {
                                if (response.data.error.status > 900) {
                                    processNonBreakingError(response.data.error)
                                } else {
                                    return reject(response)
                                }
                            } else {
                                return resolve(response)
                            }
                        }).catch((error) => {
                            if (error && error.status > 900) {
                                processNonBreakingError(error)
                            } else {
                                console.log(`Error in callBackend,error:${error} route:${url} parmsObject:${parmsObject}`)
                                return reject({ error, route: url, parmsObject })
                            }
                        })
                    break
                default:
                    alert(`bad call back end method:${method}`)
            }
        }else{
            //timed out, do a silent error
            debugger
            return reject({appTimedOut:true})
        }
    })
}

function processNonBreakingError(error) {
    console.log(` error:${error}`)
    switch (error.status) {
        case 999:
            callBackend("get", "logout", {})
                .then((results) => {
                    //clearAllCookies()
                    localStorage.removeItem('globalState')
                    window.location.href = results.data
                }).catch((err) => { 
                    //respondToError(err, "logout in Banner") 
                })
            break
        case 998:
            const newLocation = window.location.origin + "/#blockLogin"
            window.location.href = newLocation
            break
        default:
            alert(`error status over 900: ${error.status} not found`)
    }
}

