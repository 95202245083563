var moment = require('moment')
var fs = require('fs');
var path = require('path')
var _this = this
var exec = require('child_process').exec;

exports.nextRoute= (newRoute,history) =>{
        const parms = newRoute.split("?")
        const pathname = parms[0]
        const search = parms[1] ? `?${parms[1]}` : ""
        history.push({ pathname, search })
}


// export function nextRoute(newRoute, globalState, setGlobalState, logTime, goto) {
//     //just set route -- nextRoute(route, gs, sgs, null,null)
//     //set route and log time -- nextRoute(route, gs, sgs, {userId, classDbId}, null)
//     //set route and goto --  nextRoute(route, gs, sgs, null, this.props.history)
//     //set route, log time and goto --  nextRoute(route, gs, sgs, {userId, classDbId}, null)

//     //always update last seen
//     const userRoleId = globalState.userRole ? globalState.userRole.id : null  //kludge for when this is hit from the /learning/setUserRole route
//     if (userRoleId != "learner") logTime = false //only learners log time

//     const userId = logTime ? logTime.userId : globalState.userId
//     const classDbId = logTime ? logTime.classDbId : null
//     const history = goto ? goto : null
//     if (globalState.currentRoute != newRoute) {
//         const oldRoute = globalState.currentRoute
//         let timeAtSetRoute = moment()
//         if (globalState.timeAtLastSetRoute && logTime) {
//             //not the first call
//             const durationAtRoute = moment.duration(timeAtSetRoute.diff(globalState.timeAtLastSetRoute)).asMinutes()
//             if (durationAtRoute < 15) {
//                 //if more than 15 min assume they have stopped working
//                 const parmsObject = { route: oldRoute, newRoute, durationAtRoute, dateTime: timeAtSetRoute.format('YYYY-MM-DD hh:mm:ss'), userId, classDbId }
//                 callBackend("post", "app/logTimeInRoute", parmsObject)
//                 updateGlobalState(globalState, setGlobalState, { currentRoute: newRoute, timeAtLastSetRoute: timeAtSetRoute })
//             } else {
//                 updateGlobalState(globalState, setGlobalState, { currentRoute: newRoute, timeAtLastSetRoute: timeAtSetRoute })
//             }
//         } else {
//             updateGlobalState(globalState, setGlobalState, { currentRoute: newRoute, timeAtLastSetRoute: timeAtSetRoute })
//         }
//     }
//     //update currentRoute if not in help
//     if (!newRoute.includes("/helpSystem/")) {
//         let aDay = 60 * 60 * 24
//         cookie.save('currentRoute', newRoute, { maxAge: aDay * 10 })
//         //update user record
//         const parmsObject = { userId, currentRoute: newRoute }
//         callBackend("post", "app/updateUserData", parmsObject)
//     }

//     if (goto) {
//         const parms = newRoute.split("?")
//         const pathname = parms[0]
//         const search = parms[1] ? `?${parms[1]}` : ""
//         history.push({ pathname, search })
//     }
// }


exports.mysqlTimestamp = () => {
    var date = new Date(Date.now());
    var yyyy = date.getFullYear();
    var mm = date.getMonth() + 1;
    var dd = date.getDate();
    var hh = date.getHours();
    var min = date.getMinutes();
    var ss = date.getSeconds();
    var mysqlDateTime = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + ss;
    return mysqlDateTime;
}


exports.arrayDifference = function (a1, a2) {

    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}


exports.normalizeSpace = function (input) {
    // Replace repeated spaces, newlines and tabs with a single space
    return input.replace(/^\s*|\s(?=\s)|\s*$/g, "");
}

exports.encodeJsonString = function (input) {
    //use this any time you need to put uncoltrolled text into a JSON string value3
    let output = encodeURIComponent(input)
    output = output.replace(/'/g, "%27")
    return output
}

exports.decodeJsonString = function (input) {
    //use this any time you need to get uncoltrolled text from a JSON string value
    if (!input) { return input }
    let output = decodeURIComponent(input)
    return output
}

exports.processPreText = (input) => {  //used in loding content to fill in spaces

    let output = input.replace(/\*-\*/g, '   ')
    output = output.replace(/\*--\*/g, '      ')
    output = output.replace(/\*---\*/g, '         ')
    output = output.replace(/\*----\*/g, '            ')
    output = output.replace(/\*-----\*/g, '               ')
    return output
}

exports.objectDeepEquals = function (obj1, obj2) {
    if (!obj1 && !obj2) { return true }
    if (obj1 && !obj2) { return false }
    if (!obj1 && obj2) { return false }
    //Loop through properties in object 1
    for (var p in obj1) {
        //Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        switch (typeof (obj1[p])) {
            //Deep compare objects
            case 'object':
                if (!_this.objectDeepEquals(obj1[p], obj2[p])) return false;
                break;
            //Compare function code
            case 'function':
                if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
                break;
            //Compare values
            default:
                if (obj1[p] != obj2[p]) return false;
        }
    }

    //Check object 2 for any extra properties
    for (var p in obj2) {
        if (typeof (obj1[p]) == 'undefined') return false;
    }
    return true;
};

exports.killSpace = function (input) {
    if (!input) { return input }
    let output = input.replace(/\s/g, '')
    return output
}

exports.mySqlEscape = (input) => {
    var output = input.replace(/'/g, "''")
    output = output.replace(/\\"/g, '"')  //this gets rid of any that were there previously
    output = output.replace(/(?:\r\n|\r|\n)/g, ' ')
    return output
}

exports.shuffleArray = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}


exports.randomWithRemove = (array, howMany) => {
    let arrayIn = Object.assign([], array)
    if (howMany > arrayIn.length) { howMany = arrayIn.length }
    let arrayOut = []
    let arrayInRemainingItems = arrayIn.length - 1
    for (let i = 1; i <= howMany; i++) {
        const randomIndex = Math.floor((Math.random() * arrayInRemainingItems) + 0)
        arrayOut.push(arrayIn[randomIndex])
        arrayIn.splice(randomIndex, 1)
        arrayInRemainingItems--
    }
    return arrayOut
}


exports.sortByKey = function (array, key, order) {
    if (order) {
        return array.sort(compareValues(key, order))
    } else {
        return array.sort(compareValues(key))
    }

}
function compareValues(key, order = 'asc') {
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order == 'desc') ? (comparison * -1) : comparison
        );
    };
}



